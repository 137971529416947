import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../components/auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import axios from "axios";
import Spinner from "../layout/Spinner";
import moment from "moment-timezone";
import underScore from "underscore";

function HistoryDetail(props) {
  const [dataLoaded, setdataLoaded] = useState(false);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    getRiskHistory();
  }, []);

  const getRiskHistory = async () => {
    const token = await getTokenSilently();
    axios({
      method: "get",
      url: `/api/risk/riskhistory?id=${props.match.params.risksnapID}`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((snapshot) => {
        props.riskStore.updateSnapshot(snapshot.data);
        // console.log("Snapshot successfully loaded.")
        setdataLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div id="riskDetailForm">
      {dataLoaded ? (
        <div>
          <h1>Risk Change History</h1>
          <br />
          <div className="row">
            <div className="col-md-4">
              <table className="hoverTable">
                <tbody>
                  <tr>
                    <td>Change Commit ID:</td>
                    <td>{props.riskStore.snapshot._id}</td>
                  </tr>
                  <tr>
                    <td>Change User: </td>
                    <td>{props.riskStore.snapshot.changeUser.userName}</td>
                  </tr>
                  <tr>
                    <td>User Email: </td>
                    <td>{props.riskStore.snapshot.changeUser.userEmail}</td>
                  </tr>
                  <tr>
                    <td>Date of Change: </td>
                    <td>
                      {moment(props.riskStore.snapshot.date)
                        .tz("Australia/Hobart")
                        .format("DD MMMM YYYY h:mm a")}
                    </td>
                  </tr>
                  <tr>
                    <td>Today's Date: </td>
                    <td>
                      {moment()
                        .tz("Australia/Hobart")
                        .format("DD MMMM YYYY h:mm a")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-4">
              <table className="hoverTable">
                <tbody>
                  <tr>
                    <td>User Comments: </td>
                    <td>{props.riskStore.snapshot.comment}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <div className="btn-group-vertical">
                <button
                  className="btn btn-primary"
                  onClick={() => window.print()}
                >
                  Print Report
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => window.history.back()}
                >
                  Back to Risk
                </button>
              </div>
            </div>
          </div>
          <br />
          <div className="row"></div>
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped">
                <thead className="thead-dark">
                  <tr>
                    <th colSpan="4">Changed Parameters</th>
                  </tr>
                  <tr>
                    <th style={{ width: "16%" }}>Parameter</th>
                    <th style={{ width: "14%" }}>Group</th>
                    <th style={{ width: "35%" }}>Before Change</th>
                    <th style={{ width: "35%" }}>After Change</th>
                  </tr>
                </thead>
                <tbody>
                  {props.riskStore.snapshot.newRisk.archived === true ? (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        <h1>Archived Risk</h1>
                      </td>
                    </tr>
                  ) : null}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.riskName,
                    props.riskStore.snapshot.newRisk.riskName
                  ) ? null : (
                    <tr>
                      <td>Risk Name</td>
                      <td>Header</td>
                      <td>{props.riskStore.snapshot.oldRisk.riskName}</td>
                      <td>{props.riskStore.snapshot.newRisk.riskName}</td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.riskCategoryBroad.value,
                    props.riskStore.snapshot.newRisk.riskCategoryBroad.value
                  ) ? null : (
                    <tr>
                      <td>Broad Category</td>
                      <td>Header</td>
                      <td>
                        {
                          props.riskStore.snapshot.oldRisk.riskCategoryBroad
                            .value
                        }
                      </td>
                      <td>
                        {
                          props.riskStore.snapshot.newRisk.riskCategoryBroad
                            .value
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.riskCategoryNarrow.value,
                    props.riskStore.snapshot.newRisk.riskCategoryNarrow.value
                  ) ? null : (
                    <tr>
                      <td>Narrow Category</td>
                      <td>Header</td>
                      <td>
                        {
                          props.riskStore.snapshot.oldRisk.riskCategoryNarrow
                            .value
                        }
                      </td>
                      <td>
                        {
                          props.riskStore.snapshot.newRisk.riskCategoryNarrow
                            .value
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.riskOfficerPrimary.value,
                    props.riskStore.snapshot.newRisk.riskOfficerPrimary.value
                  ) ? null : (
                    <tr>
                      <td>Responsible Officer (Primary)</td>
                      <td>Header</td>
                      <td>
                        {
                          props.riskStore.snapshot.oldRisk.riskOfficerPrimary
                            .value
                        }
                      </td>
                      <td>
                        {
                          props.riskStore.snapshot.newRisk.riskOfficerPrimary
                            .value
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.riskOfficerSecondary.value,
                    props.riskStore.snapshot.newRisk.riskOfficerSecondary.value
                  ) ? null : (
                    <tr>
                      <td>Responsible Officer (Secondary)</td>
                      <td>Header</td>
                      <td>
                        {
                          props.riskStore.snapshot.oldRisk.riskOfficerSecondary
                            .value
                        }
                      </td>
                      <td>
                        {
                          props.riskStore.snapshot.newRisk.riskOfficerSecondary
                            .value
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.grossRiskAssessment
                      .potentialCauses,
                    props.riskStore.snapshot.newRisk.grossRiskAssessment
                      .potentialCauses
                  ) ? null : (
                    <tr>
                      <td>Potential Causes</td>
                      <td>Gross Risk Assessment</td>
                      <td>
                        {props.riskStore.snapshot.oldRisk.grossRiskAssessment.potentialCauses.map(
                          (item) => {
                            return <li>{item.detail}</li>;
                          }
                        )}
                      </td>
                      <td>
                        {props.riskStore.snapshot.newRisk.grossRiskAssessment.potentialCauses.map(
                          (item) => {
                            return <li>{item.detail}</li>;
                          }
                        )}
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.grossRiskAssessment
                      .potentialConsequences,
                    props.riskStore.snapshot.newRisk.grossRiskAssessment
                      .potentialConsequences
                  ) ? null : (
                    <tr>
                      <td>Potential Consequences</td>
                      <td>Gross Risk Assessment</td>
                      <td>
                        {props.riskStore.snapshot.oldRisk.grossRiskAssessment.potentialConsequences.map(
                          (item) => {
                            return <li>{item.detail}</li>;
                          }
                        )}
                      </td>
                      <td>
                        {props.riskStore.snapshot.newRisk.grossRiskAssessment.potentialConsequences.map(
                          (item) => {
                            return <li>{item.detail}</li>;
                          }
                        )}
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.grossRiskAssessment
                      .grossConsequence.label,
                    props.riskStore.snapshot.newRisk.grossRiskAssessment
                      .grossConsequence.label
                  ) ? null : (
                    <tr>
                      <td>Gross Consequence</td>
                      <td>Gross Risk Assessment</td>
                      <td>
                        {
                          props.riskStore.snapshot.oldRisk.grossRiskAssessment
                            .grossConsequence.label
                        }
                      </td>
                      <td>
                        {
                          props.riskStore.snapshot.newRisk.grossRiskAssessment
                            .grossConsequence.label
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.grossRiskAssessment
                      .grossLikelihood.label,
                    props.riskStore.snapshot.newRisk.grossRiskAssessment
                      .grossLikelihood.label
                  ) ? null : (
                    <tr>
                      <td>Gross Likelihood</td>
                      <td>Gross Risk Assessment</td>
                      <td>
                        {
                          props.riskStore.snapshot.oldRisk.grossRiskAssessment
                            .grossLikelihood.label
                        }
                      </td>
                      <td>
                        {
                          props.riskStore.snapshot.newRisk.grossRiskAssessment
                            .grossLikelihood.label
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.grossRiskAssessment
                      .grossRiskRating.label,
                    props.riskStore.snapshot.newRisk.grossRiskAssessment
                      .grossRiskRating.label
                  ) ? null : (
                    <tr>
                      <td>Gross Risk Rating</td>
                      <td>Gross Risk Assessment</td>
                      <td
                        style={{
                          backgroundColor: `${props.riskStore.snapshot.oldRisk.grossRiskAssessment.grossRiskRating.value}`,
                        }}
                      >
                        {
                          props.riskStore.snapshot.oldRisk.grossRiskAssessment
                            .grossRiskRating.label
                        }
                      </td>
                      <td
                        style={{
                          backgroundColor: `${props.riskStore.snapshot.newRisk.grossRiskAssessment.grossRiskRating.value}`,
                        }}
                      >
                        {
                          props.riskStore.snapshot.newRisk.grossRiskAssessment
                            .grossRiskRating.label
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.netRiskAssessment
                      .externalEnvironmentConsiderations,
                    props.riskStore.snapshot.newRisk.netRiskAssessment
                      .externalEnvironmentConsiderations
                  ) ? null : (
                    <tr>
                      <td>External Environment Considerations</td>
                      <td>Net Risk Assessment</td>
                      <td>
                        {props.riskStore.snapshot.oldRisk.netRiskAssessment.externalEnvironmentConsiderations.map(
                          (item) => {
                            return <li>{item.detail}</li>;
                          }
                        )}
                      </td>
                      <td>
                        {props.riskStore.snapshot.newRisk.netRiskAssessment.externalEnvironmentConsiderations.map(
                          (item) => {
                            return <li>{item.detail}</li>;
                          }
                        )}
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.netRiskAssessment
                      .internalEnvironmentConsideration.currentEnvironment,
                    props.riskStore.snapshot.newRisk.netRiskAssessment
                      .internalEnvironmentConsideration.currentEnvironment
                  ) ? null : (
                    <tr>
                      <td>
                        Current Environment (Internal Control Environment)
                      </td>
                      <td>Net Risk Assessment</td>
                      <td>
                        {props.riskStore.snapshot.oldRisk.netRiskAssessment.internalEnvironmentConsideration.currentEnvironment.map(
                          (item) => {
                            return <li>{item.detail}</li>;
                          }
                        )}
                      </td>
                      <td>
                        {props.riskStore.snapshot.newRisk.netRiskAssessment.internalEnvironmentConsideration.currentEnvironment.map(
                          (item) => {
                            return <li>{item.detail}</li>;
                          }
                        )}
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.netRiskAssessment
                      .internalEnvironmentConsideration
                      .improvementOpportunities,
                    props.riskStore.snapshot.newRisk.netRiskAssessment
                      .internalEnvironmentConsideration.improvementOpportunities
                  ) ? null : (
                    <tr>
                      <td>
                        Improvement Opportunities (Internal Control Environment)
                      </td>
                      <td>Net Risk Assessment</td>
                      <td>
                        {props.riskStore.snapshot.oldRisk.netRiskAssessment.internalEnvironmentConsideration.improvementOpportunities.map(
                          (item) => {
                            return <li>{item.detail}</li>;
                          }
                        )}
                      </td>
                      <td>
                        {props.riskStore.snapshot.newRisk.netRiskAssessment.internalEnvironmentConsideration.improvementOpportunities.map(
                          (item) => {
                            return <li>{item.detail}</li>;
                          }
                        )}
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.netRiskAssessment
                      .controllability.label,
                    props.riskStore.snapshot.newRisk.netRiskAssessment
                      .controllability.label
                  ) ? null : (
                    <tr>
                      <td>Controllability</td>
                      <td>Net Risk Assessment</td>
                      <td>
                        {
                          props.riskStore.snapshot.oldRisk.netRiskAssessment
                            .controllability.label
                        }
                      </td>
                      <td>
                        {
                          props.riskStore.snapshot.newRisk.netRiskAssessment
                            .controllability.label
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.netRiskAssessment
                      .controlEffectivenessAssessment.label,
                    props.riskStore.snapshot.newRisk.netRiskAssessment
                      .controlEffectivenessAssessment.label
                  ) ? null : (
                    <tr>
                      <td>Control Effectiveness Assessment</td>
                      <td>Net Risk Assessment</td>
                      <td>
                        {
                          props.riskStore.snapshot.oldRisk.netRiskAssessment
                            .controlEffectivenessAssessment.label
                        }
                      </td>
                      <td>
                        {
                          props.riskStore.snapshot.newRisk.netRiskAssessment
                            .controlEffectivenessAssessment.label
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.netRiskAssessment
                      .netConsequence.label,
                    props.riskStore.snapshot.newRisk.netRiskAssessment
                      .netConsequence.label
                  ) ? null : (
                    <tr>
                      <td>Net Consequence</td>
                      <td>Net Risk Assessment</td>
                      <td>
                        {
                          props.riskStore.snapshot.oldRisk.netRiskAssessment
                            .netConsequence.label
                        }
                      </td>
                      <td>
                        {
                          props.riskStore.snapshot.newRisk.netRiskAssessment
                            .netConsequence.label
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.netRiskAssessment
                      .netLikelihood.label,
                    props.riskStore.snapshot.newRisk.netRiskAssessment
                      .netLikelihood.label
                  ) ? null : (
                    <tr>
                      <td>Net Likelihood</td>
                      <td>Net Risk Assessment</td>
                      <td>
                        {
                          props.riskStore.snapshot.oldRisk.netRiskAssessment
                            .netLikelihood.label
                        }
                      </td>
                      <td>
                        {
                          props.riskStore.snapshot.newRisk.netRiskAssessment
                            .netLikelihood.label
                        }
                      </td>
                    </tr>
                  )}
                  {underScore.isEqual(
                    props.riskStore.snapshot.oldRisk.netRiskAssessment
                      .netRiskRating.label,
                    props.riskStore.snapshot.newRisk.netRiskAssessment
                      .netRiskRating.label
                  ) ? null : (
                    <tr>
                      <td>Gross Risk Rating</td>
                      <td>Gross Risk Assessment</td>
                      <td
                        style={{
                          backgroundColor: `${props.riskStore.snapshot.oldRisk.netRiskAssessment.netRiskRating.value}`,
                        }}
                      >
                        {
                          props.riskStore.snapshot.oldRisk.netRiskAssessment
                            .netRiskRating.label
                        }
                      </td>
                      <td
                        style={{
                          backgroundColor: `${props.riskStore.snapshot.newRisk.netRiskAssessment.netRiskRating.value}`,
                        }}
                      >
                        {
                          props.riskStore.snapshot.newRisk.netRiskAssessment
                            .netRiskRating.label
                        }
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-12"></div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default inject("riskStore")(observer(HistoryDetail));
