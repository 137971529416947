import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import "react-tabs/style/react-tabs.css";
import Axios from "axios";
import Spinner from "../../layout/Spinner";
import moment from "moment-timezone";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

function UserTabs(props) {
  const { getTokenSilently, loading } = useAuth0();
  const [dataloaded, setDataLoaded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [modalLoading, setModalLoading] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [role, setRole] = useState("User");
  const [error, setError] = useState("");
  const [serverError, setServerError] = useState("");
  const [projectsChanged, setProjectsChanged] = useState(false);
  const [projects, setProjects] = useState({});
  const [isProjectsSet, setIsProjectsSet] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (index !== 0) {
      setProjects({ ...props.riskStore.users[index].app_metadata.projects });
      setIsProjectsSet(true);
    }
  }, [index]);

  const getUsers = async () => {
    const token = await getTokenSilently();

    Axios({
      method: "get",
      url: `api/users/users`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((users) => {
        props.riskStore.users = users.data;
        setDataLoaded(true);
      })
      .catch((error) => console.log(error));
  };

  const saveUser = async () => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    setError("");
    setServerError("");
    if (!name || !password || !password2 || !email || !role) {
      setError("Please complete all fields.");
    } else if (password !== password2) {
      setError("Passwords do not match");
    } else if (!strongRegex.test(password)) {
      setError("Password doesn't meet complexity requirements");
    } else {
      setModalLoading(true);
      const token = await getTokenSilently();

      const data = {
        adminRole: props.riskStore.user.app_metadata.role,
        user: {
          name,
          password,
          email,
          role: role.value,
        },
      };

      Axios({
        method: "post",
        url: `api/users/new`,
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((user) => {
          props.riskStore.users.unshift(user.data);
          setModalLoading(false);
          closeModal();
          toast.success("User saved successfully!");
        })
        .catch((error) => {
          console.log(error);
          setServerError("An error occurred. Please try again later.");
          setModalLoading(false);
        });
    }
  };

  const editUser = (index) => {
    setIndex(index);
    setProjectsChanged(false);
    setError("");
    setServerError("");
    setIsProjectsSet(false);
  };

  useEffect(() => {
    if (isProjectsSet) {
      setOpenModal(true);
    }
  }, [isProjectsSet]);

  const newUser = (index) => {
    setName("");
    setEmail("");
    setPassword("");
    setPassword2("");
    setRole("User");
    setError("");
    setServerError("");
    setNewModal(true);
  };

  const handleFormChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    switch (key) {
      case "name":
        setName(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "password2":
        setPassword2(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const changeSelect = (event) => {
    setRole(event);
  };

  const changeRole = async (role, id) => {
    setError("");
    if (id === props.riskStore.user.user_id) {
      setError(
        "Cannot change your own role. Please ask another administrator to complete this task."
      );
    } else {
      setModalLoading(true);
      const token = await getTokenSilently();
      const newRole = role === "Admin" ? "User" : "Admin";
      const data = {
        adminRole: props.riskStore.user.app_metadata.role,
        user: {
          role: newRole,
          id,
        },
      };

      Axios({
        method: "patch",
        url: `api/users/updaterole`,
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((user) => {
          props.riskStore.users[index] = user.data;
          setModalLoading(false);
          closeModal();
        })
        .catch((error) => {
          console.log(error);
          setServerError("An error occurred. Please try again later.");
        });
    }
  };

  const updateApprovedProjects = async (projects, id) => {
    setError("");
    setServerError("");
    setModalLoading(true);
    const token = await getTokenSilently();
    const data = {
      adminRole: props.riskStore.user.app_metadata.role,
      user: {
        projects,
        id,
      },
    };

    Axios({
      method: "patch",
      url: `api/users/update-allowed-projects`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((user) => {
        props.riskStore.users[index] = user.data;
        setModalLoading(false);
        setProjectsChanged(false);
        resetModalState();
        toast.success("Project access updated successfully!");
      })
      .catch((error) => {
        console.log(error);
        setServerError("An error occurred. Please try again later.");
      });
  };

  const deleteUser = async (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      setError("");
      setServerError("");

      if (id === props.riskStore.user.user_id) {
        setError(
          "Cannot delete your own profile. Please ask another administrator to complete this task."
        );
      } else {
        setModalLoading(true);
        const token = await getTokenSilently();

        const data = {
          adminRole: props.riskStore.user.app_metadata.role,
          user: {
            id,
          },
        };

        Axios({
          method: "delete",
          url: `api/users/user`,
          headers: {
            accepts: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: data,
        })
          .then((user) => {
            props.riskStore.users.splice(index, 1);
            setModalLoading(false);
            closeModal();
            toast.success("User deleted successfully!");
          })
          .catch((error) => {
            console.log(error);
            setModalLoading(false);
            setServerError("An error occurred. Please try again later.");
          });
      }
    }
  };

  const closeModal = () => {
    if (projectsChanged) {
      if (
        window.confirm(
          "Are you sure you want to close this window? Unsaved changes will be lost."
        )
      ) {
        resetModalState();
      }
    } else {
      resetModalState();
    }
  };

  const resetModalState = () => {
    setIndex(0);
    setProjects({});
    setError("");
    setProjectsChanged(false);
    setOpenModal(false);
    setNewModal(false);
  };

  function updateProjectsList(projectId, access) {
    setProjects((prevProjects) => ({
      ...prevProjects,
      [projectId]: access,
    }));
    setProjectsChanged(true);
  }

  const projectVisibility = (userMetaData, category, index, role) => {
    const isAdmin = role === "Admin";
    let hidden = isAdmin ? false : true;
    let read = false;
    let write = isAdmin;

    if (!isAdmin && userMetaData && userMetaData[category.id]) {
      if (userMetaData[category.id] === "read") {
        read = true;
      } else if (userMetaData[category.id] === "write") {
        write = true;
      }
    }

    return (
      <td>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name={`inlineRadioOptions-${index}`}
            id={`inlineRadio1-${index}`}
            value="hidden"
            defaultChecked={hidden}
            onClick={() => {
              updateProjectsList(category.id, "hidden");
            }}
            disabled={isAdmin}
          />
          <label className="form-check-label" htmlFor={`inlineRadio1-${index}`}>
            Hidden
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name={`inlineRadioOptions-${index}`}
            id={`inlineRadio2-${index}`}
            value="read"
            defaultChecked={read}
            onClick={() => {
              updateProjectsList(category.id, "read");
            }}
            disabled={isAdmin}
          />
          <label className="form-check-label" htmlFor={`inlineRadio2-${index}`}>
            Read Only
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name={`inlineRadioOptions-${index}`}
            id={`inlineRadio3-${index}`}
            value="write"
            defaultChecked={write}
            onClick={() => {
              updateProjectsList(category.id, "write");
            }}
            disabled={isAdmin}
          />
          <label className="form-check-label" htmlFor={`inlineRadio3-${index}`}>
            Read/Write
          </label>
        </div>
      </td>
    );
  };

  if (loading || !dataloaded) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-10">
          <h1>User Settings Page</h1>
          <p>
            User Settings page is only visible to app administrators, and can be
            used to add users, delete users, change project visibility and
            promote/demote administrators.
          </p>
        </div>
        <div className="col-sm-2">
          <button
            style={{ float: "right" }}
            className="btn btn-primary"
            onClick={() => {
              newUser();
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> Add User
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <table className="table  table-striped table-bordered table-hover">
            <thead className="thead-dark">
              <tr>
                <th style={{ width: "24%" }}>Name</th>
                <th style={{ width: "25%" }}>Email</th>
                <th style={{ width: "9%" }}>Role</th>
                <th style={{ width: "16%" }}>Created</th>
                <th style={{ width: "16%" }}>Last Login</th>
              </tr>
            </thead>
            <tbody>
              {props.riskStore.users.map(function (user, index) {
                return (
                  <tr key={index} onDoubleClick={() => editUser(index)}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.app_metadata.role}</td>
                    <td>
                      {moment(user.created_at)
                        .tz("Australia/Hobart")
                        .format("DD/MM/YYYY h:mm A")}
                    </td>
                    <td>
                      {moment(user.last_login)
                        .tz("Australia/Hobart")
                        .format("DD/MM/YYYY h:mm A")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={openModal} onHide={() => closeModal()}>
        <Modal.Header>
          <h3>
            {index === "new"
              ? "New User (all fields required)"
              : props.riskStore.users[index].name}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-6">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <strong>Email</strong>
                    </td>
                    <td>{props.riskStore.users[index].email}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Name</strong>
                    </td>
                    <td>{props.riskStore.users[index].name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Role</strong>
                    </td>
                    <td>{props.riskStore.users[index].app_metadata.role}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-6">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <strong>Created</strong>
                    </td>
                    <td>
                      {moment(props.riskStore.users[index].created_at)
                        .tz("Australia/Hobart")
                        .format("DD/MM/YYYY h:mm A")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Last Login</strong>
                    </td>
                    <td>
                      {moment(props.riskStore.users[index].last_login)
                        .tz("Australia/Hobart")
                        .format("DD/MM/YYYY h:mm A")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Login Count</strong>
                    </td>
                    <td>{props.riskStore.users[index].logins_count}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Last IP Address</strong>
                    </td>
                    <td>{props.riskStore.users[index].last_ip}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-sm-12">
            <h3>Project Access</h3>
            {props.riskStore.users[index].app_metadata.role === "Admin" ? (
              <p>
                Admin users can see all projects by default. Therefore there is
                nothing, or everything, to see here.
              </p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "30%" }}>Narrow Category</th>
                    <th style={{ width: "50%" }}>Permissions</th>
                  </tr>
                </thead>
                <tbody>
                  {props.riskStore.settings.narrowRiskCategories
                    .sort((a, b) => a.label > b.label)
                    .map(function (category, catIndex) {
                      return (
                        <tr key={catIndex}>
                          <td>{category.label}</td>
                          {projectVisibility(
                            projects,
                            category,
                            catIndex,
                            props.riskStore.users[index].app_metadata.role
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
          <i style={{ color: "red" }}>{error}</i>
          <i style={{ color: "red" }}>{serverError}</i>
        </Modal.Body>
        <Modal.Footer>
          <div className="row flex">
            {modalLoading ? (
              <h5>Sending Request to Server, please wait...</h5>
            ) : (
              <div className="btn-group-ind">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    deleteUser(props.riskStore.users[index].user_id)
                  }
                >
                  Delete User
                </button>
                {props.riskStore.user.app_metadata.role === "Admin" &&
                  projectsChanged && (
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        updateApprovedProjects(
                          projects,
                          props.riskStore.users[index].user_id
                        )
                      }
                    >
                      Save Project Access
                    </button>
                  )}

                <button
                  className="btn btn-primary btn-sm"
                  onClick={() =>
                    changeRole(
                      props.riskStore.users[index].app_metadata.role,
                      props.riskStore.users[index].user_id
                    )
                  }
                >
                  {props.riskStore.users[index].app_metadata.role === "Admin"
                    ? "Role => User"
                    : "Role => Admin"}
                </button>
                <button
                  className="btn btn-info btn-sm"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={newModal} onHide={() => closeModal()}>
        <Modal.Header>
          <h3>New User (all fields required)</h3>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="role">Role</label>
                <Select
                  value={role}
                  options={[
                    {
                      label: "User",
                      value: "User",
                    },
                    {
                      label: "Administrator",
                      value: "Admin",
                    },
                  ]}
                  onChange={(event) => changeSelect(event)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="password">Password</label>
                <i style={{ color: "dark-grey" }}>
                  {
                    " Min 8 characters and must contain at lease one (1) lowercase letter, uppercase letter, number and special character (!@#$%^&*)"
                  }
                </i>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="name">Confirm Password</label>
                <input
                  type="password"
                  id="password2"
                  name="password2"
                  value={password2}
                  onChange={handleFormChange}
                />
              </div>
            </div>
          </form>
          <i style={{ color: "red" }}>{error}</i>
          <i style={{ color: "red" }}>{serverError}</i>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            {modalLoading ? (
              <h5>Sending Request to Server, please wait...</h5>
            ) : (
              <div className="btn-group-ind">
                <button className="btn btn-primary btn-sm" onClick={saveUser}>
                  Save User
                </button>
                <button
                  className="btn btn-info btn-sm"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default inject("riskStore")(observer(UserTabs));
