import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../components/auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import axios from "axios";
import underScore from "underscore";
import Select from "react-select";
import Spinner from "../layout/Spinner";
import { Modal } from "react-bootstrap";
import LessonPostModal from "./modals/LessonPostModal";
import moment from "moment-timezone";
import LessonHistory from "../layout/LessonHistory";
import ArchiveModal from "./modals/ArchiveModal";
import LessonArchiveModal from "./modals/LessonArchiveModal";
import { toast } from "react-toastify";

function LessonDetail(props) {
  const [lessonChanged, setLessonChanged] = useState(false);
  const [dataLoaded, setdataLoaded] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const { getTokenSilently, user, loading } = useAuth0();

  useEffect(() => {
    getLesson(props.match.params.lessonID);
  }, [props.match.params.lessonID]);

  const assessUserAccess = async (lesson, id) => {
    const projectsList = props.riskStore.user.app_metadata.projects;
    const role = props.riskStore.user.app_metadata.role === "Admin";
    const project = lesson.project._id;

    if (role) {
      setReadOnly(false);
    } else if (
      projectsList.hasOwnProperty(project) &&
      projectsList[project] === "write"
    ) {
      setReadOnly(false);
    } else if (id === "new") {
      setReadOnly(false);
    } else {
      setReadOnly(true);
      toast.warning("Your access to this lesson is read only.");
    }
  };

  const getLesson = async (lessonID) => {
    if (lessonID === "new") {
      props.lessonStore.lessonDetail = props.lessonStore.newLesson;
      props.lessonStore.origLessonDetail = props.lessonStore.newLesson;
      assessUserAccess(props.lessonStore.newLesson, lessonID);
      setdataLoaded(true);
    } else {
      const token = await getTokenSilently();
      axios
        .all([
          axios({
            method: "get",
            url: `/api/lesson/lesson?id=${props.match.params.lessonID}`,
            headers: {
              accepts: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
          axios({
            method: "get",
            url: "/api/settings/getall",
            headers: {
              accepts: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
        ])
        .then(
          axios.spread((lesson, settings) => {
            props.lessonStore.lessonDetail = lesson.data;
            props.lessonStore.origLessonDetail = lesson.data;
            props.lessonStore.settings = settings.data;
            assessUserAccess(lesson.data, lessonID);
            setdataLoaded(true);
          })
        )
        .catch((error) => {
          if (error.status === 401) {
            toast.error("You are not authorized to view this lesson");
            props.history.push("/lessons-register");
          }
          console.log(error);
        });
    }
  };

  const archiveLesson = async () => {
    setdataLoaded(false);
    props.lessonStore.lessonDetail.archived = true;
    let data = {
      updatedLesson: props.lessonStore.lessonDetail,
      changeUser: {
        userName: user.name,
        userEmail: user.email,
      },
      comment: props.lessonStore.updateComment,
    };
    const token = await getTokenSilently();
    axios({
      method: "post",
      url: "/api/lesson/archive",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then(() => {
        clearComment();
        props.history.replace("/lessons-register");
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error("You are not authorized to archive this lesson");
        }
        console.log(error);
      });
  };

  const testLessonChanged = () => {
    const lessonTest = underScore.isEqual(
      props.lessonStore.lessonDetail,
      props.lessonStore.origLessonDetail
    );
    if (lessonTest === true) {
      setLessonChanged(!lessonTest);
    } else {
      setLessonChanged(!lessonTest);
    }
  };

  const handleSimpleFormChange = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    props.lessonStore.handleSimpleFormChange(key, value);
    testLessonChanged();
  };

  const changeSelect = (event, key) => {
    props.lessonStore.handleSimpleFormChange(key, event);
    testLessonChanged();
  };

  const changeSelect2 = (event, broadKey, narrowKey) => {
    props.lessonStore.handleSimpleFormChange2(broadKey, narrowKey, event);
    testLessonChanged();
  };

  const addNewLine = (broadKey, narrowKey) => {
    props.lessonStore.addNewLine(broadKey, narrowKey);
    testLessonChanged();
  };

  const addNewLine3 = (broadKey, midKey, narrowKey) => {
    props.lessonStore.addNewLine3(broadKey, midKey, narrowKey);
    testLessonChanged();
  };

  const confirmDeleteLine3 = (broadKey, midKey, narrowKey, index) => {
    if (window.confirm("Are you sure you want to delete this line?")) {
      deleteLine3(broadKey, midKey, narrowKey, index);
    }
  };

  const deleteLine3 = (broadKey, midKey, narrowKey, index) => {
    props.lessonStore.deleteLine3(broadKey, midKey, narrowKey, index);
    testLessonChanged();
  };

  const confirmDeleteLine = (broadKey, narrowKey, index) => {
    if (window.confirm("Are you sure you want to delete this line?")) {
      deleteLine(broadKey, narrowKey, index);
    }
  };

  const deleteLine = (broadKey, narrowKey, index) => {
    props.lessonStore.deleteLine(broadKey, narrowKey, index);
    testLessonChanged();
  };

  const stripHTML = (htmlString) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = htmlString;
    return tmp.textContent;
  };

  const editLine = (event, broadKey, narrowKey, index) => {
    const text = event.currentTarget.textContent;
    const subText = stripHTML(text);
    props.lessonStore.editLine(broadKey, narrowKey, index, subText);
    testLessonChanged();
  };

  const editLine3 = (event, broadKey, midKey, narrowKey, index) => {
    const text = event.currentTarget.textContent;
    const subText = stripHTML(text);
    props.lessonStore.editLine3(broadKey, midKey, narrowKey, index, subText);
    testLessonChanged();
  };

  const clearComment = () => {
    props.lessonStore.updateComment = "";
    props.lessonStore.archiveCheck = "";
  };

  const postLesson = async () => {
    setdataLoaded(false);
    let data = {
      updatedLesson: props.lessonStore.lessonDetail,
      changeUser: {
        userName: user.name,
        userEmail: user.email,
      },
      comment: props.lessonStore.updateComment,
    };

    const token = await getTokenSilently();
    if (props.match.params.lessonID === "new") {
      axios({
        method: "post",
        url: "/api/lesson/add",
        json: true,
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((lesson) => {
          props.history.push(`/lesson/${lesson.data._id}`);
        })
        .catch((error) => {
          if (error.status === 401) {
            toast.error("You are not authorized to add a new lesson");
          }
          console.log(error);
        });
    } else {
      axios({
        method: "post",
        url: "/api/lesson/update",
        json: true,
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((lesson) => {
          getLesson(lesson.data._id);
          clearComment();
        })
        .catch((error) => {
          if (error.status === 401) {
            toast.error("You are not authorized to update this lesson");
          }
          console.log(error);
        });
    }
  };

  if (loading || !dataLoaded) {
    return <Spinner />;
  }

  const toggleModalOpen = (modalType) => {
    switch (modalType) {
      case "postModal":
        setPostModal(!postModal);
        break;
      case "archiveModal":
        setArchiveModal(!archiveModal);
        break;
      default:
        break;
    }
  };

  return (
    <div id="riskDetailForm">
      <div>
        <div className="sticky">
          <h1 className="form-heading">Lesson Detail Edit Form</h1>
          <nav className="navbar navbar-light">
            <div className="mr-auto">
              <p id="form-paragraph">
                {readOnly
                  ? "Due to Project level permissions, your access to this lesson is read-only. Contact an administrator if you need edit access."
                  : "Please edit the form fields below and click 'save' to record in database. All fields are required."}
              </p>
            </div>
            <div className="ml-auto">
              <div className="btn-group">
                {lessonChanged && !readOnly ? (
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      if (
                        props.lessonStore.lessonDetail.project !== null &&
                        props.lessonStore.lessonDetail.project !== undefined &&
                        props.lessonStore.lessonDetail.project !== ""
                      ) {
                        toggleModalOpen("postModal");
                      } else {
                        toast.error("Please select a project before saving.");
                      }
                    }}
                  >
                    Save
                  </button>
                ) : null}
                <button
                  className="btn btn-outline-danger"
                  onClick={() => props.history.push("/lessons-register")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </nav>
        </div>
        <br />
        <Modal show={postModal} onHide={() => toggleModalOpen("postModal")}>
          <Modal.Body>
            <LessonPostModal />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={() => {
                postLesson();
                toggleModalOpen("postModal");
              }}
            >
              Update Lesson
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                toggleModalOpen("postModal");
                clearComment();
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <form>
          <div className="form-row">
            <div className="col-md-10">
              <div className="form-group">
                <label htmlFor="project">Project</label>
                <Select
                  isDisabled={readOnly}
                  value={props.lessonStore.lessonDetail.project}
                  options={
                    props.riskStore.user.app_metadata.role === "Admin"
                      ? props.riskStore.settings.narrowRiskCategories
                      : props.riskStore.settings.narrowRiskCategories.filter(
                          (category) => {
                            return (
                              props.riskStore.user.app_metadata.projects.hasOwnProperty(
                                category._id
                              ) &&
                              props.riskStore.user.app_metadata.projects[
                                category._id
                              ] === "write"
                            );
                          }
                        )
                  }
                  onChange={(event) => changeSelect(event, "project")}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="lessonID">Lesson Status & ID</label>

                {props.lessonStore.lessonDetail.archived === true ? (
                  <div
                    style={{
                      backgroundColor: "#ff0000",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <h4>Archived</h4>
                    <p style={{ fontSize: ".8rem" }}>
                      {props.lessonStore.lessonDetail._id === undefined
                        ? "NEW"
                        : `${props.lessonStore.lessonDetail._id}`}
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#00cd00",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <h4>Active</h4>
                    <p style={{ fontSize: ".8rem" }}>
                      {props.lessonStore.lessonDetail._id === undefined
                        ? "NEW"
                        : `${props.lessonStore.lessonDetail._id}`}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-10">
              <div className="form-group">
                <label htmlFor="area">Area</label>
                <Select
                  isDisabled={readOnly}
                  value={props.lessonStore.lessonDetail.area}
                  options={props.riskStore.settings.lessonArea}
                  onChange={(event) => changeSelect(event, "area")}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="rating">Rating</label>
                <Select
                  isDisabled={readOnly}
                  value={props.lessonStore.lessonDetail.rating}
                  options={props.riskStore.settings.lessonRating}
                  onChange={(event) => changeSelect(event, "rating")}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-10">
              <div className="form-group">
                <label htmlFor="originator">Originator</label>
                <Select
                  isDisabled={readOnly}
                  value={props.lessonStore.lessonDetail.originator}
                  options={props.riskStore.settings.originators}
                  onChange={(event) => changeSelect(event, "originator")}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="dateIdentified">Date Identified</label>
                <input
                  disabled={readOnly}
                  type="date"
                  name="dateIdentified"
                  id="dateIdentified"
                  value={moment(
                    props.lessonStore.lessonDetail.dateIdentified
                  ).format("YYYY-MM-DD")}
                  onChange={handleSimpleFormChange}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="whatWorked">What worked well?</label>
                <textarea
                  disabled={readOnly}
                  name="whatWorked"
                  id="whatWorked"
                  rows="4"
                  value={props.lessonStore.lessonDetail.whatWorked}
                  onChange={handleSimpleFormChange}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="whatImproved">What can be improved?</label>
                <textarea
                  disabled={readOnly}
                  name="whatImproved"
                  id="whatImproved"
                  rows="4"
                  value={props.lessonStore.lessonDetail.whatImproved}
                  onChange={handleSimpleFormChange}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="notes">Notes</label>
                <textarea
                  disabled={readOnly}
                  name="notes"
                  id="notes"
                  rows="8"
                  value={props.lessonStore.lessonDetail.notes}
                  onChange={handleSimpleFormChange}
                />
              </div>
            </div>
          </div>
        </form>
        <br />
        <LessonHistory />
        {readOnly || props.lessonStore.lessonDetail.archived ? null : (
          <button
            className="btn btn-outline-danger"
            onClick={() => toggleModalOpen("archiveModal")}
          >
            Archive Lesson
          </button>
        )}
        <Modal
          show={archiveModal}
          onHide={() => toggleModalOpen("archiveModal")}
        >
          <Modal.Body>
            <LessonArchiveModal />
          </Modal.Body>
          <Modal.Footer>
            {props.lessonStore.archiveCheck.toUpperCase() === "ARCHIVE" ? (
              <button
                className="btn btn-danger"
                onClick={() => {
                  archiveLesson();
                  toggleModalOpen("archiveModal");
                }}
              >
                Archive Lesson
              </button>
            ) : null}
            <button
              className="btn btn-primary"
              onClick={() => {
                toggleModalOpen("archiveModal");
                clearComment();
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default inject("riskStore", "lessonStore")(observer(LessonDetail));
