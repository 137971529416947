import React, { useState } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import Axios from "axios";

function BroadCategories(props) {
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [id, setId] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");
  const [insignificant, setInsignificant] = useState("");
  const [minor, setMinor] = useState("");
  const [moderate, setModerate] = useState("");
  const [major, setMajor] = useState("");
  const [catastrophic, setCatastrophic] = useState("");
  const { getTokenSilently } = useAuth0();

  const saveChanges = async () => {
    setLoading(true);
    const token = await getTokenSilently();
    if (index === "new") {
      const data = {
        type: "broadRiskCategories",
        label: label.trim(),
        value: value.trim(),
        description,
        insignificant,
        minor,
        moderate,
        major,
        catastrophic,
      };
      Axios({
        method: "post",
        url: "/api/settings/add",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          const settingData = setting.data;
          let newSetting = { ...settingData };
          console.log(newSetting);
          newSetting.id = newSetting._id;
          props.riskStore.addNewSetting("broadRiskCategories", newSetting);
          // props.riskStore.settings.broadRiskCategories.push({
          //   newSetting,
          // });
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      const data = {
        _id: id,
        id,
        label,
        value,
        description,
        insignificant,
        minor,
        moderate,
        major,
        catastrophic,
      };
      Axios({
        method: "post",
        url: "/api/settings/modify",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          props.riskStore.settings.broadRiskCategories[index] = data;
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const toggleModalOpen = () => {
    setShowModal(!showModal);
    setId("");
    setLabel("");
    setValue("");
    setDescription("");
    setInsignificant("");
    setMinor("");
    setModerate("");
    setMajor("");
    setCatastrophic("");
    setChanged(false);
  };

  const newCategory = () => {
    setIndex("new");
    setId("");
    setLabel("");
    setValue("");
    setDescription("");
    setInsignificant("");
    setMinor("");
    setModerate("");
    setMajor("");
    setCatastrophic("");
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const editRow = (index) => {
    setIndex(index);
    setId(props.riskStore.settings.broadRiskCategories[index].id);
    setLabel(props.riskStore.settings.broadRiskCategories[index].label);
    setValue(props.riskStore.settings.broadRiskCategories[index].value);
    setDescription(
      props.riskStore.settings.broadRiskCategories[index].description
    );
    setInsignificant(
      props.riskStore.settings.broadRiskCategories[index].insignificant
    );
    setMinor(props.riskStore.settings.broadRiskCategories[index].minor);
    setModerate(props.riskStore.settings.broadRiskCategories[index].moderate);
    setMajor(props.riskStore.settings.broadRiskCategories[index].major);
    setCatastrophic(
      props.riskStore.settings.broadRiskCategories[index].catastrophic
    );
    setShowModal(true);
    setChanged(false);
    setLoading(false);
  };

  const deleteCategory = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this category? This is irreversible."
      )
    ) {
      setLoading(true);
      const token = await getTokenSilently();
      const data = {
        _id: id,
      };
      Axios({
        method: "delete",
        url: "/api/settings/delete",
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((setting) => {
          props.riskStore.deleteSetting("broadRiskCategories", index);
          // props.riskStore.settings.broadRiskCategories.splice(index, 1);
          toggleModalOpen();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  const handleFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "catName":
        setLabel(value);
        setValue(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "insignificant":
        setInsignificant(value);
        break;
      case "minor":
        setMinor(value);
        break;
      case "moderate":
        setModerate(value);
        break;
      case "major":
        setMajor(value);
        break;
      case "catastrophic":
        setCatastrophic(value);
        break;
      default:
        break;
    }
    if (!changed) {
      setChanged(true);
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={() => toggleModalOpen()}>
        <Modal.Header>
          <h3>
            {index === "new"
              ? "Create New Category (all fields required)"
              : "Edit Category - " + label}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="catName">Category Name</label>
                <input
                  type="text"
                  id="catName"
                  name="catName"
                  value={label}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="2"
                  value={description}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="insignificant">Insignificant Example</label>
                <textarea
                  name="insignificant"
                  id="insignificant"
                  cols="30"
                  rows="2"
                  value={insignificant}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="minor">Minor Example</label>
                <textarea
                  name="minor"
                  id="minor"
                  cols="30"
                  rows="2"
                  value={minor}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="moderate">Moderate Example</label>
                <textarea
                  name="moderate"
                  id="moderate"
                  cols="30"
                  rows="2"
                  value={moderate}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="major">Major Example</label>
                <textarea
                  name="major"
                  id="major"
                  cols="30"
                  rows="2"
                  value={major}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="catastrophic">Catastrophic Example</label>
                <textarea
                  name="catastrophic"
                  id="catastrophic"
                  cols="30"
                  rows="2"
                  value={catastrophic}
                  onChange={handleFormChange}
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            {loading ? (
              <h5>Sending Request to Server, please wait...</h5>
            ) : (
              <div className="btn-group-ind">
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    deleteCategory();
                  }}
                >
                  Delete Category
                </button>
                {changed ? (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      saveChanges();
                    }}
                  >
                    Save
                  </button>
                ) : null}
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    toggleModalOpen();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-sm-10">
          <h1>Broad Categories</h1>
          <p>
            Broad categories represent the highest level of risk, and are the
            logical groupings underneath the organisation as a whole. There are
            another subset of categories under this level (narrow categories)
            which can be used to further segment the data.
          </p>
          <p>
            Using the table below you are able to update the category names, and
            add new categories. The first two fields are fairly self
            explanatory, but the final 5 fields are the specific descriptors
            that will be shown to staff to guide them when choosing a risk
            consequence (in the risk detail screen).
          </p>
        </div>
        <div className="col-sm-2">
          <button
            style={{ float: "right" }}
            className="btn btn-primary"
            onClick={() => {
              newCategory();
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> New Category
          </button>
        </div>
      </div>
      <br />
      <table className="table table-striped table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th style={{ width: "10%" }}>Name</th>
            <th style={{ width: "15%" }}>Description</th>
            <th style={{ width: "15%" }}>Insignificant</th>
            <th style={{ width: "15%" }}>Minor</th>
            <th style={{ width: "15%" }}>Moderate</th>
            <th style={{ width: "15%" }}>Major</th>
            <th style={{ width: "15%" }}>Catastrophic</th>
          </tr>
        </thead>
        <tbody>
          {props.riskStore.settings.broadRiskCategories.map(function (
            category,
            index
          ) {
            return (
              <tr key={index} onDoubleClick={() => editRow(index)}>
                <td>{category.label}</td>
                <td>{category.description}</td>
                <td>{category.insignificant}</td>
                <td>{category.minor}</td>
                <td>{category.moderate}</td>
                <td>{category.major}</td>
                <td>{category.catastrophic}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
    </div>
  );
}

export default inject("riskStore")(observer(BroadCategories));
