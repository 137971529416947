import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { useAuth0 } from "./components/auth/react-auth0-spa";
import PrivateRoute from "./components/auth/PrivateRoute";
import history from "./utils/history";

import Navbar from "./components/layout/Navbar";
import Register from "./components/pages/Register";
import Reports from "./components/pages/Reports";
import Settings from "./components/pages/Settings";
import RiskDetail from "./components/pages/RiskDetail";
import HistoryDetail from "./components/pages/HistoryDetail";
import Home from "./components/pages/Home";
import Footer from "./components/layout/Footer";
import NotFound from "./components/pages/NotFound";

import Spinner from "./components/layout/Spinner";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./App.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LessonsRegister from "./components/pages/LessonsRegister";
import LessonDetail from "./components/pages/LessonDetail";
import LessonHistoryDetail from "./components/pages/LessonHistoryDetail";
import IssuesRegister from "./components/pages/IssuesRegister";
import issuesDetail from "./components/pages/IssuesDetail";
import IssueHistoryDetail from "./components/pages/IssueHistoryDetail";

toast.configure();

const App = () => {
  const { loading } = useAuth0();

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="App, pageContainer">
      <div className="contentWrap">
        <Router history={history}>
          <header>
            <Navbar />
          </header>
          <div className="container">
            <Switch>
              <Route path="/" exact={true} component={Home} />
              <Route path="/home" exact={true} component={Home} />
              <PrivateRoute path="/reports" exact={true} component={Reports} />
              <PrivateRoute
                path="/settings"
                exact={true}
                component={Settings}
              />
              <PrivateRoute path="/risk/:riskID" component={RiskDetail} />
              <PrivateRoute path="/register" component={Register} />
              <PrivateRoute path="/risk-register" component={Register} />
              <PrivateRoute path="/issue/:issueID" component={issuesDetail} />
              <PrivateRoute
                path="/issues-register"
                component={IssuesRegister}
              />
              <PrivateRoute path="/lesson/:lessonID" component={LessonDetail} />
              <PrivateRoute
                path="/lessons-register"
                component={LessonsRegister}
              />
              <PrivateRoute
                path="/riskhistory/:risksnapID"
                component={HistoryDetail}
              />
              <PrivateRoute
                path="/lessonhistory/:lessonSnapID"
                component={LessonHistoryDetail}
              />
              <PrivateRoute
                path="/issuehistory/:issueSnapID"
                component={IssueHistoryDetail}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
          <footer>
            <Footer />
          </footer>
        </Router>
      </div>
    </div>
  );
};

export default App;
