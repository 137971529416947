import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import BroadCategories from "./BroadCategories";
import NarrowCategories from "./NarrowCategories";
import Likelihoods from "./Likelihoods";
import Controllability from "./Controllability";
import ControlEffectiveness from "./ControlEffectiveness";
import Roles from "./Roles";
import Axios from "axios";
import Spinner from "../../layout/Spinner";
import CopyTabs from "./CopyTabs";

function RiskTabs(props) {
  const { getTokenSilently, loading } = useAuth0();
  const [dataloaded, setDataLoaded] = useState(false);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    const token = await getTokenSilently();

    Axios({
      method: "get",
      url: "/api/settings/getall",
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((settings) => {
        props.riskStore.settings = settings.data;
        setDataLoaded(true);
      })
      .catch((error) => console.log(error));
  };

  if (loading || !dataloaded) {
    return <Spinner />;
  }

  return (
    <div>
      <h1>Risk Settings</h1>
      <p>
        Using the tabs below you can update the risk settings for the entire
        app. Double click a row to make changes, use the button to create a new
        entry.
      </p>
      {/* <p>
        <i>
          Please Note: The risk matrix (last tab) is currently unable to be
          changed via the front-end. If changes to this portion need to be made
          please lodge a developer request.
        </i>
      </p> */}
      <Tabs>
        <TabList>
          <Tab>Broad Categories</Tab>
          <Tab>Narrow Categories</Tab>
          <Tab>Likelihood</Tab>
          <Tab>Controllability</Tab>
          <Tab>Control Effectiveness</Tab>
          <Tab>Roles</Tab>
          <Tab>Copy Risk</Tab>
        </TabList>
        <TabPanel>
          <BroadCategories />
        </TabPanel>
        <TabPanel>
          <NarrowCategories updateData={updateData} />
        </TabPanel>
        <TabPanel>
          <Likelihoods />
        </TabPanel>
        <TabPanel>
          <Controllability />
        </TabPanel>
        <TabPanel>
          <ControlEffectiveness />
        </TabPanel>
        <TabPanel>
          <Roles />
        </TabPanel>
        <TabPanel>
          <CopyTabs />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default inject("riskStore")(observer(RiskTabs));
