import React, { useState } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import "react-tabs/style/react-tabs.css";
import moment from "moment-timezone";
import { Modal } from "react-bootstrap";
import Axios from "axios";

function ProfileTab(props) {
  const { getTokenSilently, logout } = useAuth0();
  const [modalLoading, setModalLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");
  const [serverError, setServerError] = useState("");

  const popModal = () => {
    setPassword2("");
    setPassword("");
    setModalLoading(false);
    setError("");
    setServerError("");
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleFormChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    switch (key) {
      case "password":
        setPassword(value);
        break;
      case "password2":
        setPassword2(value);
        break;
      default:
        break;
    }
  };

  const changePassword = async () => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    setError("");
    setServerError("");
    if (!password || !password2) {
      setError("Please complete all fields.");
    } else if (password !== password2) {
      setError("Passwords do not match");
    } else if (!strongRegex.test(password)) {
      setError("Password doesn't meet complexity requirements");
    } else {
      setModalLoading(true);
      const token = await getTokenSilently();

      const data = {
        id: props.riskStore.user.user_id,
        password,
      };

      Axios({
        method: "patch",
        url: `api/users/changepassword`,
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((user) => {
          logout();
        })
        .catch((error) => {
          console.log(error);
          setServerError("An error occurred. Please try again later.");
          setModalLoading(false);
        });
    }
  };

  const tableRow = (category, projectAccess) => {
    const rowColor = projectAccess === "write" ? "table-success" : "table-info";

    return (
      <tr key={category.id} className={"text-dark"}>
        <td>{category.label}</td>
        <td className={"text-center text-dark " + rowColor}>{projectAccess}</td>
      </tr>
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <h1>Profile - {props.riskStore.user.name}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <strong>Email</strong>
                </td>
                <td>{props.riskStore.user.email}</td>
              </tr>
              <tr>
                <td>
                  <strong>Name</strong>
                </td>
                <td>{props.riskStore.user.name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Role</strong>
                </td>
                <td>{props.riskStore.user.app_metadata.role}</td>
              </tr>
              <tr>
                <td>
                  <div className="btn-group-ind">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={popModal}
                    >
                      Change Password
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-sm-6">
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <strong>Created</strong>
                </td>
                <td>
                  {moment(props.riskStore.user.created_at)
                    .tz("Australia/Hobart")
                    .format("DD/MM/YYYY h:mm A")}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Last Login</strong>
                </td>
                <td>
                  {moment(props.riskStore.user.last_login)
                    .tz("Australia/Hobart")
                    .format("DD/MM/YYYY h:mm A")}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Login Count</strong>
                </td>
                <td>{props.riskStore.user.logins_count}</td>
              </tr>
              <tr>
                <td>
                  <strong>Last IP Address</strong>
                </td>
                <td>{props.riskStore.user.last_ip}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-sm-7">
          <h2>Project Access</h2>
          {props.riskStore.user.app_metadata.role === "Admin" ? (
            <p>Admins automatically have access to all projects.</p>
          ) : (
            <table className="table table-striped table-sm table-bordered table-condensed">
              <thead>
                <tr>
                  <th scope="col">Project</th>
                  <th scope="col">Access Level</th>
                </tr>
              </thead>
              <tbody>
                {props.riskStore.settings.narrowRiskCategories.map((category) =>
                  tableRow(
                    category,
                    props.riskStore.user.app_metadata.projects[category.id]
                  )
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Modal show={openModal} onHide={() => closeModal()}>
        <Modal.Header>
          <h3>Change Password</h3>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Note: </strong>if password change is successful, you will be
            logged out. Please login back in using your new password.
          </p>
          <form>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="password">Password</label>
                <i style={{ color: "dark-grey" }}>
                  {
                    " Min 8 characters and must contain at lease one (1) lowercase letter, uppercase letter, number and special character (!@#$%^&*)"
                  }
                </i>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="name">Confirm Password</label>
                <input
                  type="password"
                  id="password2"
                  name="password2"
                  value={password2}
                  onChange={handleFormChange}
                />
              </div>
            </div>
          </form>
          <i style={{ color: "red" }}>{error}</i>
          <i style={{ color: "red" }}>{serverError}</i>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            {modalLoading ? (
              <h5>Sending Request to Server, please wait...</h5>
            ) : (
              <div className="btn-group-ind">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={changePassword}
                >
                  Change Password
                </button>
                <button
                  className="btn btn-info btn-sm"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default inject("riskStore")(observer(ProfileTab));
