import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import "react-tabs/style/react-tabs.css";
import Spinner from "../../layout/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import Axios from "axios";
import moment from "moment-timezone";
import { Modal } from "react-bootstrap";
import Select from "react-select";

function APITabs(props) {
  const { getTokenSilently, loading } = useAuth0();
  const [dataloaded, setDataLoaded] = useState(false);
  const [machines, setMachines] = useState([]);
  const [chosenMachine, setChosenMachine] = useState(null);
  const [index, setIndex] = useState(null);
  const [saving, setSaving] = useState(false);
  const [grantObject, setGrantObject] = useState({});
  const [projectsLoaded, setProjectsLoaded] = useState(false);
  const [projectsChanged, setProjectsChanged] = useState(false);
  const [machineChanged, setMachineChanged] = useState(false);
  const [newSecret, setNewSecret] = useState("");
  const [showSecretModal, setShowSecretModal] = useState(false);

  useEffect(() => {
    getMachines();
  }, []);

  const getMachines = async () => {
    const token = await getTokenSilently();

    Axios({
      method: "get",
      url: `api/machines/machines`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((machines) => {
        setMachines(machines.data);
        setDataLoaded(true);
      })
      .catch((error) => console.log(error));
  };

  const getClientGrants = async (id) => {
    const token = await getTokenSilently();
    const postData = {
      adminRole: props.riskStore.user.app_metadata.role,
      machine: {
        id,
      },
    };

    Axios({
      method: "post",
      url: `api/machines/client-grants`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: postData,
    })
      .then((grantObject) => {
        setGrantObject(grantObject.data[0]);
        setProjectsChanged(false);
        setProjectsLoaded(true);
      })
      .catch((error) => console.log(error));
  };

  const saveMachine = async () => {
    setSaving(true);

    if (machineChanged) {
      await saveMachineDetails();
    }
    if (projectsChanged) {
      await updateClientGrants();
    }

    setChosenMachine(null);
    setGrantObject([]);
    setMachineChanged(false);
    setProjectsChanged(false);
    setSaving(false);
    setIndex(null);
  };

  const saveMachineDetails = async () => {
    const token = await getTokenSilently();
    const postData = {
      adminRole: props.riskStore.user.app_metadata.role,
      machine: {
        id: chosenMachine.client_id,
        name: chosenMachine.name,
        description: chosenMachine.description,
      },
    };

    Axios({
      method: "patch",
      url: `api/machines/client-grants`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: postData,
    })
      .then((projects) => {
        toast.success("API Client updated successfully");
      })
      .catch((error) => {
        toast.error("Error updating API Client. Please try again.");
        console.log(error);
      });
  };

  const addButton = async () => {
    await modalOpen(
      {
        name: "",
        description: "",
      },
      "new"
    );
  };

  const addMachine = async () => {
    const token = await getTokenSilently();
    const postData = {
      adminRole: props.riskStore.user.app_metadata.role,
      machine: {
        name: chosenMachine.name,
        description: chosenMachine.description,
      },
    };

    Axios({
      method: "post",
      url: `api/machines/new`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: postData,
    })
      .then((client) => {
        toast.success("Machine/API Client created successfully");
        setNewSecret(client.data.client_secret);
        setIndex(null);
        setShowSecretModal(true);
        getMachines();
      })
      .catch((error) => {
        toast.error("Error deleting the Machine/API Client. Please try again.");
        console.log(error);
      });
  };

  const deleteMachine = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this machine? This is irreversible."
      )
    ) {
      const token = await getTokenSilently();
      const postData = {
        adminRole: props.riskStore.user.app_metadata.role,
        machine: {
          id: chosenMachine.client_id,
        },
      };

      Axios({
        method: "delete",
        url: `api/machines/client`,
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: postData,
      })
        .then((projects) => {
          toast.success("Machine/API Client deleted successfully");
          getMachines();
          closeModal();
        })
        .catch((error) => {
          toast.error(
            "Error deleting the Machine/API Client. Please try again."
          );
          console.log(error);
        });
    }
  };

  const updateClientGrants = async () => {
    const token = await getTokenSilently();
    const postData = {
      adminRole: props.riskStore.user.app_metadata.role,
      machine: {
        id: grantObject.id,
        scope: grantObject.scope,
      },
    };

    Axios({
      method: "patch",
      url: `api/machines/client-grants`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: postData,
    })
      .then((projects) => {
        toast.success("Project access updated successfully");
      })
      .catch((error) => {
        toast.error("Error updating project access. Please try again.");
        console.log(error);
      });
  };

  const rotateSecret = async () => {
    if (
      window.confirm(
        "Are you sure you want to rotate the secret? This will invalidate the current secret."
      )
    ) {
      const token = await getTokenSilently();
      const postData = {
        adminRole: props.riskStore.user.app_metadata.role,
        machine: {
          id: chosenMachine.client_id,
        },
      };

      Axios({
        method: "post",
        url: `api/machines/rotate-secret`,
        headers: {
          accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: postData,
      })
        .then((response) => {
          const newSecret = response.data.client_secret;
          setNewSecret(newSecret);
          setShowSecretModal(true);
        })
        .catch((error) => {
          toast.error("Error updating project access. Please try again.");
          console.log(error);
        });
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(newSecret);
    toast.success("Secret copied to clipboard!");
  };

  const modalOpen = async (machine, index) => {
    setChosenMachine(machine);
    setIndex(index);
    setGrantObject([]);
    setProjectsLoaded(false);
    setProjectsChanged(false);
    if (index !== "new") {
      await getClientGrants(machine.client_id);
    }
  };

  const closeModal = () => {
    if (machineChanged || projectsChanged) {
      if (
        window.confirm(
          "You have unsaved changes. Are you sure you want to close?"
        )
      ) {
        setChosenMachine(null);
        setIndex(null);
        setGrantObject([]);
        setMachineChanged(false);
        setProjectsChanged(false);
      }
    } else {
      setChosenMachine(null);
      setIndex(null);
      setGrantObject([]);
    }
  };

  const displayInstructions = () => {
    // todo: display instructions
  };

  const addProjectAccess = (projectId) => {
    setGrantObject((prevGrantObject) => {
      let updatedGrantObject = { ...prevGrantObject };
      if (!Array.isArray(updatedGrantObject.scope)) {
        updatedGrantObject.scope = [];
      }
      if (!updatedGrantObject.scope.includes(projectId)) {
        updatedGrantObject.scope.push(projectId);
      }
      return updatedGrantObject;
    });
    setProjectsChanged(true);
  };

  const removeProjectAccess = (projectId) => {
    setGrantObject((prevGrantObject) => {
      let updatedGrantObject = { ...prevGrantObject };
      updatedGrantObject.scope = updatedGrantObject.scope.filter(
        (project) => project !== projectId
      );
      return updatedGrantObject;
    });
    setProjectsChanged(true);
  };

  const projectVisibility = (grantObject, category, index) => {
    let hidden = true;
    let read = false;

    if (grantObject.scope && grantObject.scope.includes(category.id)) {
      read = true;
      hidden = false;
    }

    return (
      <td>
        <div
          className="form-check form-check-inline"
          style={{ whiteSpace: "nowrap" }}
        >
          <input
            className="form-check-input"
            type="radio"
            name={`inlineRadioOptions-${index}`}
            id={`inlineRadio1-${index}`}
            value="hidden"
            defaultChecked={hidden}
            style={{ width: "50%" }}
            onClick={() => {
              removeProjectAccess(category.id);
            }}
          />
          <label className="form-check-label" htmlFor={`inlineRadio1-${index}`}>
            No Access
          </label>
        </div>
        <div
          className="form-check form-check-inline"
          style={{ whiteSpace: "nowrap" }}
        >
          <input
            className="form-check-input"
            type="radio"
            name={`inlineRadioOptions-${index}`}
            id={`inlineRadio2-${index}`}
            value="read"
            defaultChecked={read}
            style={{ width: "50%" }}
            onClick={() => {
              addProjectAccess(category.id);
            }}
          />
          <label className="form-check-label" htmlFor={`inlineRadio2-${index}`}>
            Read Only
          </label>
        </div>
      </td>
    );
  };

  if (loading || !dataloaded) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-10">
          <h1>API Settings Page</h1>
          <p>
            API Settings page is only visible to app administrators, and can be
            used to add/remove API only users, define the projects they can
            access, and rotate secrets.
          </p>
          <p>Double click on an API user to view and edit their details.</p>
        </div>
        <div className="col-sm-2">
          <button
            style={{ float: "right" }}
            className="btn btn-primary"
            onClick={async () => {
              await addButton();
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} /> Add Machine
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <table className="table  table-striped table-bordered table-hover">
            <thead className="thead-dark">
              <tr>
                <th style={{ width: "20%" }}>Name</th>
                <th style={{ width: "46%" }}>Description</th>
                <th style={{ width: "17%" }}>Created</th>
                {/*<th style={{ width: "17%" }}>Updated</th>*/}
              </tr>
            </thead>
            <tbody>
              {machines.map(function (machine, index) {
                return (
                  <tr
                    key={index}
                    onDoubleClick={() => modalOpen(machine, index)}
                  >
                    <td>{machine.name}</td>
                    <td>{machine.description}</td>
                    <td>
                      {machine.client_metadata &&
                      machine.client_metadata.created
                        ? moment(machine.client_metadata.created)
                            .tz("Australia/Hobart")
                            .format("DD/MM/YYYY h:mm A")
                        : ""}
                    </td>
                    {/*<td>*/}
                    {/*  {machine.client_metadata &&*/}
                    {/*  machine.client_metadata.updated*/}
                    {/*    ? moment(machine.client_metadata.updated)*/}
                    {/*        .tz("Australia/Hobart")*/}
                    {/*        .format("DD/MM/YYYY h:mm A")*/}
                    {/*    : ""}*/}
                    {/*</td>*/}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={index !== null} onHide={() => closeModal()}>
        <Modal.Header>
          <h3>Machine Details</h3>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={chosenMachine ? chosenMachine.name : ""}
                  onChange={(e) => {
                    e.preventDefault();
                    const updatedMachine = {
                      ...chosenMachine,
                    };
                    updatedMachine.name = e.target.value;
                    setChosenMachine(updatedMachine);
                    if (!machineChanged) {
                      setMachineChanged(true);
                    }
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="name">
                  Description <i>(140 characters max)</i>
                </label>
                <textarea
                  rows="2"
                  maxLength={140}
                  id="description"
                  name="description"
                  value={chosenMachine ? chosenMachine.description : ""}
                  onChange={(e) => {
                    e.preventDefault();
                    const updatedMachine = {
                      ...chosenMachine,
                    };
                    updatedMachine.description = e.target.value;
                    setChosenMachine(updatedMachine);
                    if (!machineChanged) {
                      setMachineChanged(true);
                    }
                    const charCount = e.target.value.length;
                    const charCountElement = document.getElementById(
                      "charCount"
                    );
                    if (charCount <= 100) {
                      charCountElement.style.color = "green";
                    } else if (charCount <= 139) {
                      charCountElement.style.color = "orange";
                    } else {
                      charCountElement.style.color = "red";
                    }
                    charCountElement.innerText = `${charCount}/140`;
                  }}
                />
                <div
                  style={{ textAlign: "right", color: "lightgrey" }}
                  id="charCount"
                >
                  {chosenMachine ? chosenMachine.description.length : 0}/140
                </div>
              </div>
            </div>
            {index === "new" ? (
              <>
                <p>
                  Please enter the details on the form above and hit save. Once
                  the Machine/API Client has been created you will be able to
                  view the secret and assign permissions.
                </p>
              </>
            ) : (
              <>
                <div className="form-row">
                  <div className="col-md-12">
                    <label htmlFor="id">Client ID</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        id="id"
                        name="id"
                        value={chosenMachine ? chosenMachine.client_id : ""}
                        readOnly
                        style={{ marginRight: "10px" }}
                      />
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => displayInstructions()}
                      >
                        API Instructions
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12">
                    <label htmlFor="secret">Secret</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="password"
                        id="secret"
                        name="secret"
                        value="xxxxxxxxxxxxxxxxxxxx"
                        readOnly
                        style={{ marginRight: "10px" }}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => rotateSecret()}
                      >
                        Rotate Secret
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12">
                    <h3>Project Access</h3>
                    {projectsLoaded ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th style={{ width: "30%" }}>Narrow Category</th>
                            <th style={{ width: "50%" }}>Permissions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.riskStore.settings.narrowRiskCategories
                            .sort((a, b) => a.label > b.label)
                            .map(function (category, catIndex) {
                              return (
                                <tr key={catIndex}>
                                  <td>{category.label}</td>
                                  {projectVisibility(
                                    grantObject,
                                    category,
                                    catIndex
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    ) : (
                      <p>Getting latest access data. Please wait...</p>
                    )}
                  </div>
                </div>
              </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            {saving ? (
              <h5>Sending request to Server, please wait...</h5>
            ) : (
              <div className="btn-group-ind">
                {index !== "new" && (
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteMachine()}
                  >
                    Delete
                  </button>
                )}
                {index !== "new" ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => saveMachine()}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => addMachine()}
                  >
                    Add
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  onClick={() => closeModal()}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showSecretModal}
        onHide={() => {
          if (
            window.confirm(
              "Are you sure you want to close? The secret will not be shown again."
            )
          ) {
            setNewSecret("");
            setShowSecretModal(false);
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Secret</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please copy and securely store the new secret. For security reasons
            this secret will not be shown again.
          </p>
          <p>The new secret is: </p>
          <pre>{newSecret}</pre>
          <button
            className={"btn btn-sm btn-outline-info"}
            onClick={copyToClipboard}
          >
            Copy to Clipboard
          </button>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            variant="secondary"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to close? The secret will not be shown again."
                )
              ) {
                setNewSecret("");
                setShowSecretModal(false);
              }
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default inject("riskStore")(observer(APITabs));
