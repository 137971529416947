import React, { useState, useEffect, Fragment } from "react";
import { useAuth0 } from "../auth/react-auth0-spa";
import { inject, observer } from "mobx-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Axios from "axios";
import Spinner from "../layout/Spinner";

import RiskTabs from "./settingsComps/RiskTabs";
import UserTabs from "./settingsComps/UserTabs";
import ProfileTab from "./settingsComps/ProfileTab";
import LessonTabs from "./settingsComps/LessonTabs";
import IssueTabs from "./settingsComps/IssueTabs";
import APITabs from "./settingsComps/APITabs";

function Settings(props) {
  const { getTokenSilently, loading, user } = useAuth0();
  const [dataloaded, setDataLoaded] = useState(false);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const token = await getTokenSilently();

    Axios({
      method: "get",
      url: `api/users/user?id=${user.sub}`,
      headers: {
        accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((user) => {
        props.riskStore.user = user.data;
        setDataLoaded(true);
      })
      .catch((error) => console.log(error));
  };

  if (loading || !dataloaded) {
    return <Spinner />;
  }

  return (
    <div>
      <h1>Settings</h1>
      <Tabs defaultIndex={0}>
        <TabList>
          <Tab>Profile</Tab>
          <Tab>Risk</Tab>
          <Tab>Lesson</Tab>
          <Tab>Issue</Tab>
          {props.riskStore.user.app_metadata.role === "Admin" ? (
            <Fragment>
              <Tab>Users</Tab>
              <Tab>API</Tab>
            </Fragment>
          ) : null}
        </TabList>
        <TabPanel>
          <ProfileTab />
        </TabPanel>
        <TabPanel>
          <RiskTabs />
        </TabPanel>
        <TabPanel>
          <LessonTabs />
        </TabPanel>
        <TabPanel>
          <IssueTabs />
        </TabPanel>
        {props.riskStore.user.app_metadata.role === "Admin" ? (
          <Fragment>
            <TabPanel>
              <UserTabs />
            </TabPanel>
            <TabPanel>
              <APITabs />
            </TabPanel>
          </Fragment>
        ) : null}
      </Tabs>
    </div>
  );
}

export default inject("riskStore")(observer(Settings));
