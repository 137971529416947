import { observable, decorate, runInAction } from "mobx";

class riskStore {
  riskList = [];
  riskDetail = {};
  origRiskDetail = {};
  snapshot = {};
  updateComment = "";
  archiveCheck = "";
  settings = {};
  user = {};
  users = [];

  newRisk = {
    archived: false,
    riskName: "",
    riskCategoryBroad: {
      value: "Political",
      label: "Political",
    },
    riskCategoryNarrow: {
      value: "",
      label: "",
    },
    riskOfficerPrimary: {
      value: "Board Chair",
      label: "Board Chair",
    },
    riskOfficerSecondary: {
      value: "Chief Executive Officer",
      label: "Chief Executive Officer",
    },
    grossRiskAssessment: {
      potentialCauses: [],
      potentialConsequences: [],
      grossConsequence: {
        value: 1,
        label: "Insignificant",
      },
      grossLikelihood: {
        value: 1,
        label: "Rare",
      },
      grossRiskRating: {
        label: "Low",
        value: "#00cd00",
      },
    },
    netRiskAssessment: {
      externalEnvironmentConsiderations: [],
      internalEnvironmentConsideration: {
        currentEnvironment: [],
        improvementOpportunities: [],
      },
      controllability: {
        value: "Controllable",
        label: "Controllable",
      },
      controlEffectivenessAssessment: {
        value: "Satisfactory",
        label: "Satisfactory",
      },
      netConsequence: {
        value: 1,
        label: "Insignificant",
      },
      netLikelihood: {
        value: 1,
        label: "Rare",
      },
      netRiskRating: {
        label: "Low",
        value: "#00cd00",
      },
    },
    riskHistory: [],
  };

  addNewSetting = (category, setting) => {
    runInAction(() => {
      this.settings[category].push(setting);
    });
  };

  deleteSetting = (category, index) => {
    runInAction(() => {
      this.settings[category].splice(index, 1);
    });
  };

  archiveSetting = (category, index) => {
    runInAction(() => {
      this.settings[category][index].archived = !this.settings[category][index]
        .archived;
    });
  };

  updateSnapshot = (snapshot) => {
    if (snapshot.oldRisk === undefined) {
      snapshot.oldRisk = this.newRisk;
    }
    runInAction(() => {
      this.snapshot = snapshot;
    });
  };

  handleUpdateComment = (value) => {
    runInAction(() => {
      this.updateComment = value;
    });
  };

  handleUpdateArchiveCheck = (value) => {
    runInAction(() => {
      this.archiveCheck = value;
    });
  };

  calculateRiskColours = () => {
    this.RiskColours("gross");
    this.RiskColours("net");
  };

  RiskColours = (operator) => {
    const Low = {
      label: "Low",
      value: "#00cd00",
    };

    const Mod = {
      label: "Mod",
      value: "#ffff00",
    };

    const High = {
      label: "High",
      value: "#ffc100",
    };

    const Ext = {
      label: "Ext",
      value: "#ff0000",
    };

    const likely1 = `${operator}Likelihood`;
    const conseq1 = `${operator}Consequence`;
    const rating1 = `${operator}RiskRating`;
    const assessment1 = `${operator}RiskAssessment`;
    const likelihood = this.riskDetail[assessment1][likely1]["label"];
    const consequence = this.riskDetail[assessment1][conseq1]["label"];

    runInAction(() => {
      switch (likelihood) {
        case "Rare":
          switch (consequence) {
            case "Insignificant":
            case "Minor":
            case "Moderate":
              this.riskDetail[assessment1][rating1] = Low;
              break;
            case "Major":
              this.riskDetail[assessment1][rating1] = Mod;
              break;
            case "Catastrophic":
              this.riskDetail[assessment1][rating1] = High;
              break;
            default:
              break;
          }
          break;
        case "Unlikely":
          switch (consequence) {
            case "Insignificant":
            case "Minor":
              this.riskDetail[assessment1][rating1] = Low;
              break;
            case "Moderate":
              this.riskDetail[assessment1][rating1] = Mod;
              break;
            case "Major":
            case "Catastrophic":
              this.riskDetail[assessment1][rating1] = High;
              break;
            default:
              break;
          }
          break;
        case "Possible":
          switch (consequence) {
            case "Insignificant":
              this.riskDetail[assessment1][rating1] = Low;
              break;
            case "Minor":
            case "Moderate":
              this.riskDetail[assessment1][rating1] = Mod;
              break;
            case "Major":
              this.riskDetail[assessment1][rating1] = High;
              break;
            case "Catastrophic":
              this.riskDetail[assessment1][rating1] = Ext;
              break;
            default:
              break;
          }
          break;
        case "Likely":
          switch (consequence) {
            case "Insignificant":
            case "Minor":
              this.riskDetail[assessment1][rating1] = Mod;
              break;
            case "Moderate":
              this.riskDetail[assessment1][rating1] = High;
              break;
            case "Major":
            case "Catastrophic":
              this.riskDetail[assessment1][rating1] = Ext;
              break;
            default:
              break;
          }
          break;
        case "Almost Certain":
          switch (consequence) {
            case "Insignificant":
              this.riskDetail[assessment1][rating1] = Mod;
              break;
            case "Minor":
            case "Moderate":
              this.riskDetail[assessment1][rating1] = High;
              break;
            case "Major":
            case "Catastrophic":
              this.riskDetail[assessment1][rating1] = Ext;
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    });
  };

  handleSimpleFormChange = (key, value) => {
    runInAction(() => {
      this.riskDetail[key] = value;
    });
  };

  handleSimpleFormChange2 = (broadKey, narrowKey, value) => {
    runInAction(() => {
      this.riskDetail[broadKey][narrowKey] = value;
      this.calculateRiskColours();
    });
  };

  handleTypeAhead = (value, key) => {
    runInAction(() => {
      this.riskDetail[key] = value;
    });
  };

  handleTypeAhead2Key = (value, broadKey, narrowKey) => {
    runInAction(() => {
      this.riskDetail[broadKey][narrowKey] = value;
    });
  };

  addNewLine = (broadKey, narrowKey) => {
    runInAction(() => {
      const tempIndex = this.riskDetail[broadKey][narrowKey].length + 1;
      this.riskDetail[broadKey][narrowKey].push({
        index: tempIndex,
        detail: "",
      });
    });
  };

  addNewLine3 = (broadKey, midKey, narrowKey) => {
    runInAction(() => {
      const tempIndex = this.riskDetail[broadKey][midKey][narrowKey].length + 1;
      this.riskDetail[broadKey][midKey][narrowKey].push({
        index: tempIndex,
        detail: "",
      });
    });
  };

  editLine = (broadKey, narrowKey, index, content) => {
    runInAction(() => {
      this.riskDetail[broadKey][narrowKey][index].detail = content;
    });
  };

  editLine3 = (broadKey, midKey, narrowKey, index, content) => {
    runInAction(() => {
      this.riskDetail[broadKey][midKey][narrowKey][index].detail = content;
    });
  };

  deleteLine = (broadKey, narrowKey, index) => {
    runInAction(() => {
      // const arrayIndex = this.riskDetail[broadKey][narrowKey].findIndex(
      //   (item) => item.id === id
      // );
      this.riskDetail[broadKey][narrowKey].splice(index, 1);
    });
  };

  deleteLine3 = (broadKey, midKey, narrowKey, index) => {
    runInAction(() => {
      this.riskDetail[broadKey][midKey][narrowKey].splice(index, 1);
    });
  };

  findRiskDetail = (id) => {
    return this.riskList.find((risk) => risk._id === id);
  };
}

decorate(riskStore, {
  riskList: observable,
  riskDetail: observable,
  snapshot: observable,
  updateComment: observable,
  archiveCheck: observable,
  user: observable,
  users: observable,
});

export default new riskStore();
