import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="footerDiv">
        <footer>
          <div className="text-center">
            <i>Made with </i>💓
            <i>
              {" "}
              by Risen Solutions for Macquarie Point Development Corporation
            </i>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
