import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  const backgroundStyle = {
    backgroundImage:
      'url("https://active-cdn-risen-solutions.sfo2.cdn.digitaloceanspaces.com/clients/MPDC/NotFound.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
  };

  return (
    <div className="row" style={backgroundStyle}>
      <div
        className="col-sm-6"
        style={{ textAlign: "center", paddingTop: "30vh" }}
      >
        <h3 style={{ color: "black", fontSize: "xx-large" }}>
          Sorry, but we haven't built that route yet. Perhaps you'd like to
          return to the <Link to="/">Home Page</Link>?
        </h3>
      </div>
      <div className="col-sm-6"></div>
    </div>
  );
};

export default NotFound;
